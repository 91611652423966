export const navlinks = [
    {
        id: 1,
        name: 'A propos',
        href: '#about'
    },
    {
        id: 2,
        name: 'Nos services',
        href: '#services'
    },
    {
        id: 3,
        name: 'Nos Technologies',
        href: '#technologies'
    },
    {
        id: 4,
        name: 'So Securigaz',
        href: '#sosecurigaz'
    },
    {
        id: 5,
        name: 'Blogs',
        href: '/blogs'
    },
]